import './MTSPage.css'
import Header from "../Header/Header";

import mainImg from '../../img/МТС/1_Main.png';
import mainImg2 from '../../img/МТС/7_screen.png';
import mainImg3 from '../../img/МТС/8_screen.png';
import mainImg4 from '../../img/МТС/9_screen.png';
import img1 from '../../img/МТС/3_1_pic.png';
import img2 from '../../img/МТС/3_2_pic.png';
import img3 from '../../img/МТС/3_3_pic.png';
import img4 from '../../img/МТС/3_4_pic.png';
import img5 from '../../img/МТС/11_collage.png';
import img6 from '../../img/МТС/11_c2_ollage.png';
import img7 from '../../img/МТС/11_c3_ollage.png';
import img8 from '../../img/МТС/11_c4_ollage.png';

import raskadrovka from '../../img/mtsFrame.png';
import mainAbout from '../../img/МТС/2_text.png';
// import img6 from '../../img/МТС/';

import video1 from '../../img/МТС/new gif/shot 1_1.webm'
import video2 from '../../img/МТС/new gif/shot 2.webm'
import video3 from '../../img/МТС/new gif/shot 3.webm'
import video4 from '../../img/МТС/new gif/shot 4.webm'
import video5 from '../../img/МТС/new gif/shot 5.webm'
import video6 from '../../img/МТС/new gif/shot 6.webm'
import video1_mp4 from '../../img/МТС/new gif/shot 1_1.mp4'
import video2_mp4 from '../../img/МТС/new gif/shot 2.mp4'
import video3_mp4 from '../../img/МТС/new gif/shot 3.mp4'
import video4_mp4 from '../../img/МТС/new gif/shot 4.mp4'
import video5_mp4 from '../../img/МТС/new gif/shot 5.mp4'
import video6_mp4 from '../../img/МТС/new gif/shot 6.mp4'

import aboutMts from '../../img/portfolio-about-small/mts.png'

const mtsTitle = 'Cyber Sation. Конференция по кибербозапосности от MTC'
const mtsSubtitle = 'Сотни умных тезисов от спикеров МТС заполнили площадку «Кибердом». Контент, разработанный нами, заполнил все экраны.  Интро ролики, видеовизитки спикеров, инфографика, тематические блоки и расписание - теперь команде художников есть о чем рассказать внукам. '


export default function MTSPage() {
    return(
    <div className='mts'>
        <div className="section project__about">
            <div className="project__about-container">
                <p className="project__text project__about-title">{mtsTitle}</p>
                <p className="project__text project__about-text">{mtsSubtitle}</p>
            </div>
            <img className="project__about-image" src={aboutMts} loading="lazy"/>
        </div>
        <img className='mts__mainImg' src={mainImg}></img>
        <div style={{backgroundColor: 'black'}} className='mts__container'>
            <iframe className='mts__youtube-1' src="https://www.youtube.com/embed/LUBcKrVb_28?si=h5tluLovDultwVoz&amp;start=34" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <img className='mts__mainImg' src={mainAbout}></img>
            <div className='mts__images-1'>
                <img className='mts__imgOf4 div1' src={img1}></img>
                <img className='mts__imgOf4 div2' src={img2}></img>
                <img className='mts__imgOf4 div3' src={img3}></img>
                <img className='mts__imgOf4 div4' src={img4}></img>
            </div>
            <img className='mts__mainImg' src={raskadrovka}></img>
            <div className='video'>
                <div className='video__container'>
                    <p className='video__title'>развитие технологий</p>
                    <video
                        className='video-small'
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        playsInline={true}
                    >
                        <source src={video1} type='video/webm'></source>
                        <source src={video1_mp4} type='video/mp4'></source>
                    </video>
                </div>
                <div className='video__container'>
                    <p className='video__title'>курс на цифровизацию</p>
                    <video
                        className='video-small'
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        playsInline={true}>
                        <source src={video2} type='video/webm'></source>
                        <source src={video2_mp4} type='video/mp4'></source>
                        </video>
                </div>
            </div>
            <div className='video'>
                <div className='video__container'>
                    <p className='video__title'>грамотное использование русурсов</p>
                    <video
                        className='video-small'
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        playsInline={true}>
                        <source src={video3} type='video/webm'></source>
                        <source src={video3_mp4} type='video/mp4'></source>
                        </video>
                </div>
                <div className='video__container'>
                    <p className='video__title'>готовность к&nbsp;любым изменениям</p>
                    <video
                        className='video-small'
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        playsInline={true}>
                        <source src={video4} type='video/webm'></source>
                        <source src={video4_mp4} type='video/mp4'></source>
                        </video>
                </div>
            </div>
            <div className='video'>
                <div className='video__container'>
                    <p className='video__title'>оптимизация бизнес&nbsp;-&nbsp;процессов</p>
                    <video
                        className='video-small'
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        playsInline={true}>
                        <source src={video5} type='video/webm'></source>
                        <source src={video5_mp4} type='video/mp4'></source>
                        </video>
                </div>
                <div className='video__container'>
                    <p className='video__title'>накопление полученных&nbsp;знаний</p>
                    <video
                        className='video-small'
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        playsInline={true}>
                        <source src={video6} type='video/webm'></source>
                        <source src={video6_mp4} type='video/mp4'></source>
                        </video>
                </div>
            </div>
            <img className='mts__mainImg' src={mainImg2}></img>
            <img className='mts__mainImg' src={mainImg3}></img>
            <img className='mts__mainImg' src={mainImg4}></img>
            <iframe className='mts__youtube-1' src="https://www.youtube.com/embed/elILzUhX9fI?si=vOMak4GXgsq3VxgH&amp;start=6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <div className='mts__images-1'>
                <img className='mts__imgOf4 div1' src={img5}></img>
                <img className='mts__imgOf4 div2' src={img6}></img>
                <img className='mts__imgOf4 div3' src={img7}></img>
                <img className='mts__imgOf4 div4' src={img8}></img>
            </div>
        </div>
    </div>);
}